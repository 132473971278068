import React from "react";
import { graphql, StaticQuery } from "gatsby";
import theme from "../theme/theme.yaml";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import { FaTag, FaRss, FaPaperPlane } from "react-icons/fa";
import config from "../../content/meta/config";
import { Link } from "gatsby";

const GamePage = (props) => {
  return (
    <React.Fragment>
      <Article theme={theme}>
        <header>
          <Headline title="ゲームハードの一覧" theme={theme} />
        </header>
        <section
          style={{
            margin: "2em 0",
            padding: "2em",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            lineHeight: "1.5",
          }}
        >
          <h1 style={{ textAlign: "center", color: "#333", marginBottom: "1em" }}>
            ゲーム機の性能を見てみよう
          </h1>
          <div style={{ textAlign: "justify" }}>
            <p>
              ゲーム機は、私たちに楽しいゲームを提供するために開発されたデバイスです。多くの人々が長時間をかけて開発し、その緻密な構造や性能は非常に魅力的であり、その開発プロセスには驚きのエピソードが数多く詰まっています。
            </p>
            <p>
              今やPCやスマートフォンが主流の時代ですが、各時代の最先端技術を凌ぐゲーム機の内部をじっくりと眺めてみて、その進化の歴史を楽しみながら比較してみてください。
            </p>
          </div>
        </section>

        <section
          style={{
            margin: "2em 0",
            padding: "2em",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            lineHeight: "1.5",
          }}
        >
        <h2 style={{ marginBottom: "0.5em" }}>ゲーム機年表</h2>
        <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>
              <Link to="/game/chronology_gameconsole">主要なゲーム機発売年表</Link>
            </li>
        </ul>
        
        </section>

        <section
          style={{
            margin: "2em 0",
            padding: "2em",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            lineHeight: "1.5",
          }}
        >
          <h2 style={{ marginBottom: "0.5em" }}>任天堂</h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>
              <Link to="/game/nintendo-switch">Nintendo Switch</Link>
            </li>
            <li><font color="lightgray">Wii U</font></li>
            <li><font color="lightgray">Nintendo 3DS</font></li>
            <li><font color="lightgray">Wii</font></li>
            <li><Link to="/game/nds">ニンテンドーDS</Link></li>
            <li><font color="lightgray">GameCube</font></li>
            <li><Link to="/game/n64">NINTENDO64</Link></li>
            <li><Link to="/game/gba">ゲームボーイアドバンス</Link></li>
            <li><Link to="/game/gb">ゲームボーイ</Link></li>
            <li><Link to="/game/sfc">スーパーファミコン</Link></li>
            <li><Link to="/game/fc">ファミリーコンピュータ</Link></li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>
            SCE (SONY Computer Entertainment)
          </h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li><font color="lightgray">PlayStation 5</font></li>
            <li><font color="lightgray">PlayStation 4</font></li>
            <li><font color="lightgray">PlayStation Vita</font></li>
            <li><font color="lightgray">PlayStation 3</font></li>
            <li><Link to="/game/psp">PlayStation Portable</Link></li>
            <li><Link to="/game/ps2">PlayStation 2</Link></li>
            <li><Link to="/game/ps">PlayStation</Link></li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>Microsoft</h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li><font color="lightgray">Xbox Series X/S</font></li>
            <li><font color="lightgray">Xbox One</font></li>
            <li><Link to="/game/xbox360">Xbox 360</Link></li>
            <li><Link to="/game/xbox">Xbox</Link></li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>SEGA</h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li><Link to="/game/dc">DreamCast</Link></li>
            <li><Link to="/game/ss">SEGA SATURN</Link></li>
            <li><font color="lightgray">メガドライブ</font></li>
            <li><font color="lightgray">ゲームギア</font></li>
          </ul>
        </section>
      </Article>
        <Seo
      title="Digital Colors - ゲーム機の性能と歴史の解説"
      description="ゲーム機の具体的な性能や「実はここが凄い！」という情報をまとめています。ファミコン、ニンテンドーDSからPlayStation 5やNintendo Switchなどの最新世代まで分かりやすく解説。ゲーム機の進化の歴史を楽しんでください。"
      ogpimage=""
    />
    </React.Fragment>
  );
};

export default GamePage;
